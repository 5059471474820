import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { PublicHomeComponent } from "./modules/public-home/components/public-home/public-home.component";

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 120],
  onSameUrlNavigation: 'reload',
  relativeLinkResolution: 'legacy',
  useHash: true
};

const routes: Routes = [
  // {
  //   path:'',
  //   redirectTo:'karmayogi',
  //   pathMatch: 'full'
  // },
  {
    path: '',
    component: PublicHomeComponent,
    pathMatch: 'full'
  },
  {
    path: 'aboutus',
    loadChildren: () => import('../app/modules/about-us/about-us.module').then(m => m.AboutUsModule),
  },
  {
    path: 'tenders',
    loadChildren: () => import('../app/modules/tenders/tenders.module').then(m => m.TendersModule),
  },
  {
    path: 'notifications',
    loadChildren: () => import('../app/modules/notifications/notifications.module').then(m => m.NotificationsModule),
  },
  // {
  //   path: 'railway',
  //   loadChildren :()=> import('../app/modules/railway/railway.module').then(m=>m.RailwayModule),
  // },{
  //   path: 'post',
  //   loadChildren :()=> import('../app/modules/postal/postal.module').then(m=>m.PostalModule),
  // },
  {
    path: 'contentList',
    loadChildren: () => import('../app/modules/contentList/content-list.module').then(m => m.ContentListModule),
  },
  {
    path: 'mdoList',
    loadChildren: () => import('../app/modules/mdoUserList/mdo-user-list.module').then(m => m.MdoUserListModule),
  },
  {
    path: 'career',
    loadChildren: () => import('./modules/career/careerpage.module').then(m => m.CareerpageModule),
  },
  {
    path: 'contactus',
    loadChildren: () => import('./modules/contact-us/contact-us.module').then(m => m.ContactUsModule),
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./modules/public-maintenance/public-maintenance.module').then(m => m.PublicMaintenanceModule),
  },

  {
    path: 'karmayogi-saptah',
    loadChildren: () => import('../app/modules/microsites/microsites.module').then(m => m.MicrositesModule),
  },
  // {
  //   path: 'karmayogi',
  //   loadChildren :()=> import('../app/modules/public-home/public-home.module').then(m=>m.PublicHomeModule),
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
